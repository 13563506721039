<template>
  <base-modal-ex :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    has-modal-card>
    <div class="model-content">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            <slot name="text-title" />
          </p>
        </header>
        <section class="modal-card-body">
          <div class="field">
            <label class="label">Quote Template Name</label>
            <input v-model="templateName"
              class="input is-uppercase"
              type="text"
              placeholder="Template Name">
            <span v-if="$v.templateName.$dirty && !$v.templateName.required"
              class="help is-danger ml-1">Name Required</span>
            <span v-if="$v.templateName.$dirty && !$v.templateName.unique"
              class="help is-danger ml-1">Name has been taken.</span>
          </div>

          <div class="field">
            <label class="label">Template Type</label>
            <div class="select is-fullwidth">
              <select v-model="quoteType">
                <option value="null"
                  disabled>Select</option>
                <option v-for="(type, index) in quoteTemplateTypes"
                  :key="index">{{ type }}</option>
              </select>
            </div>
            <span v-if="$v.quoteType.$dirty && !$v.quoteType.required"
              class="help is-danger ml-1">Template Type Required</span>
          </div>

          <div class="field">
            <label class="label">Vehicle Body Style <span class="has-text-grey is-italic">(Required for Labour)</span> </label>
            <div class="select is-fullwidth">
              <select v-model="bodyId">
                <option value="null"
                  disabled>Select</option>
                <option v-for="(style, index) in bodyStyles"
                  :key="index"
                  :value="style.id">{{ style.name }}</option>
              </select>
            </div>
          </div>

          <div class="field">
            <label class="label">Labour Times <span class="has-text-grey is-italic">(Required for Labour)</span> </label>
            <div class="select is-fullwidth">
              <select v-model="timesType">
                <option value="null"
                  disabled>Select</option>
                <option v-for="(type, index) in labourTimesTypes"
                  :key="index">{{ type }}</option>
              </select>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot"
          :style="`justify-content: flex-${buttonLocation}`">
          <button class="button is-primary"
            @click="add">Create</button>
          <button class="button"
            @click="close">Close</button>
        </footer>
      </div>
    </div>
  </base-modal-ex>
</template>
<script>

import BaseModalEx from '@/components/BulmaModal/BaseModalEx'
import { LabourTimeTypes, QuoteTemplateTypes }from '@/enums'
import { QuoteTemplateModel } from '@/classes/viewmodels'
import Guid from '@/components/Guid'
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import QuoteTemplateRoutes from '@/views/quotetemplate/route-types'
import QuoteTemplateService from '@/services/QuoteTemplateService'

export default {
  name: 'QuoteNewTemplateModal',
  components: {
    BaseModalEx
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'fadeIn'
    },
    animationOut: {
      type: String,
      default: 'fadeOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end'
    },
    templates: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      bodyId: null,
      isActive: this.active || false,
      templateName: null,
      timesType: null,
      quoteType: null
    }
  },
  validations: {
    templateName: {
      required,
      unique(value) {
        return !this.templateNames.includes(value)
      }
     },
    quoteType: { required }
  },
  computed: {
    ...mapGetters('vehicleOptions', {
      storeBodyShapes: 'bodyShapes'
    }),
    bodyStyles() {
      return this.storeBodyShapes
    },
    labourTimesTypes() {
      return LabourTimeTypes
    },
    quoteTemplateTypes() {
      return QuoteTemplateTypes
    },
    templateNames() {
      return this.templates.map(template => template.name)
    }
  },
  watch: {
    active(value) {
      this.isActive = value
    }
  },
  methods: {
    async add() {
      this.$v.$touch()
      if (this.$v.$error) return

      let templateData = {
        templateId: Guid.empty(),
        bodyId: this.bodyId,
        companyId: this.$company.info.companyId,
        name: this.templateName.toUpperCase(),
        quoteType: Object.keys(QuoteTemplateTypes).find(
          key => QuoteTemplateTypes[key] == this.quoteType),
        timesType: this.timesType
      }
      const newTemplate = new QuoteTemplateModel(templateData)
      const template = await QuoteTemplateService.insertQuoteTemplate(newTemplate)
      this.$router.push({
        path: `${QuoteTemplateRoutes.QuoteTemplateListView.path}/${template.templateId}` })
    },
    close() {
      this.isActive = false
      this.$emit('update:active', false)
    }
  }
}
</script>
