<template>
  <div v-if="permissions.hasRead">
    <app-detail-header :show-toggle="true"
      :show-delete="false"
      :show-save="false"
      :show-cancel="false"
      :show-print="false"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <quote-template-list-side-menu v-model="filter"
          @filter="filterList"
          @reset="resetFilter" />
      </div>
      <div class="column">
        <div class="tile is-parent">
          <article class="tile is-child box detail-page-tile">
            <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
              :columns="columns"
              :is-loading="isTableLoading"
              :page-index="filter.pageIndex"
              :page-size="filter.pageSize"
              :sort-column="filter.sortColumn"
              :sort-order="filter.sortOrder"
              :total-rows="totalRows"
              @pageChanged="onPageChange"
              @sort="sort">

              <tr v-for="(template, index) in templates"
                :key="index">
                <td class="is-uppercase">
                  <router-link :to="{ path: template.templateId }"
                    append>
                    {{ template.name }}
                  </router-link>
                </td>
                <td>{{ quoteTemplateTypes[template.quoteType] }}</td>
                <td>{{ template.createdDate ? $filters.formatDateTimezone(`${template.createdDate}Z`, $userInfo.locale) : '' }}</td>
                <td>{{ template.timesType }}</td>
                <td class="has-text-centered action-column">
                  <button class="button is-primary is-small is-inverted tooltip"
                    data-tooltip="Click to edit"
                    @click="editTemplate(template)">
                    <span class="icon">
                      <i class="mdi mdi-lead-pencil mdi-24px" />
                    </span>
                  </button>
                  <button class="button is-danger is-small is-inverted"
                    @click="activeConfirmDeleteModal(template)"
                    :disabled="!permissions.hasDelete"
                    :class="{ 'tooltip' : !permissions.hasDelete }"
                    :data-tooltip="[!permissions.hasDelete ? 'No Permission' : '']">
                    <span class="icon is-medium">
                      <i class="mdi mdi-delete mdi-24px" />
                    </span>
                  </button>
                </td>
              </tr>

              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <span icon="icon is-large">
                      <i class="mdi mdi-48px mdi-emoticon-sad" />
                    </span>
                    <p>Nothing</p>
                  </div>
                </section>
              </template>
            </bulma-table>

            <quote-new-template-modal v-if="isNewTemplateModalActive"
              :active.sync="isNewTemplateModalActive"
              :templates.sync="templates">
              <p slot="text-title">Create a new template</p>
            </quote-new-template-modal>

            <confirm-modal v-if="templateDeleted"
              :active.sync="isConfirmModalActive"
              @ok="deleteTemplate(true)"
              @cancel="deleteTemplate(false)"
              :ok-text="'Yes'"
              :cancel-text="'No'">
              <p slot="text-title">Delete Quote Template</p>
              <p slot="text-content"
                class="has-text-danger">
                Quote Template <span class="has-text-weight-bold">{{ templateDeleted.name }}</span>
                will be deleted. Continue?
              </p>
            </confirm-modal>

            <quote-template-edit-modal v-if="isEditModalActive"
              :active.sync="isEditModalActive"
              :template.sync="templateUpdated"
              :templates.sync="templates">
              <p slot="text-title">Edit Template {{ templateUpdated.name }}</p>
            </quote-template-edit-modal>
          </article>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="is-flex is-justify-content-center pt-5">
      <div class="notification is-danger is-flex is-size-4">
        You do not have permissions on this page
      </div>
    </div>
  </div>
</template>

<script>
import AppDetailHeader from '@/components/AppDetailHeader'
import { ConfirmModal } from '@/components/BulmaModal'
import { AppHeaderButtonTypes } from '@/enums'
import BulmaTable from '@/components/BulmaTable'
import { QuoteTemplateListColumns } from './columns'
import QuoteTemplateListSideMenu from './QuoteTemplateListSideMenu'
import { QuoteTemplateTypes } from '@/enums'
import QuoteNewTemplateModal from '@/components/QuoteTemplateModal/QuoteNewTemplateModal'
import QuoteTemplateEditModal from '@/components/QuoteTemplateModal/QuoteTemplateEditModal'
import QuoteTemplateService from '@/services/QuoteTemplateService'

export default {
  name: 'QuoteTemplateListView',
  components: {
    AppDetailHeader,
    BulmaTable,
    ConfirmModal,
    QuoteTemplateListSideMenu,
    QuoteNewTemplateModal,
    QuoteTemplateEditModal
  },
  data() {
    return {
      filter: {
        name: '',
        sortColumn: QuoteTemplateListColumns[0].name,
        sortOrder: QuoteTemplateListColumns[0].defaultOrder,
        pageIndex: 1,
        pageSize: 30
      },
      isConfirmModalActive: false,
      isEditModalActive: false,
      isNewTemplateModalActive: false,
      isTableLoading: false,
      templateDeleted: null,
      templateUpdated: null,
      templates: null,
      totalRows: 0
    }
  },
  computed: {
    columns() {
      return QuoteTemplateListColumns
    },
    filterKey() {
      return this.$userInfo ? `${this.$userInfo.sessionId}|${this.$route.meta.fkey}` : ''
    },
    permissions() {
      return this.$user.permissions?.find(p => p.codeName == this.$route.meta.id) || null
    },
    quoteTemplateTypes() {
      return QuoteTemplateTypes
    }
  },
  async created() {
    await this.getQuoteTemplates()
  },
  methods: {
    activeNewTemplate() {
      this.isNewTemplateModalActive = true
    },

    activeConfirmDeleteModal(template) {
      this.templateDeleted = template
      this.isConfirmModalActive = true
    },

    async deleteTemplate(confirmDelete) {
      if (!confirmDelete) {
        this.templateDeleted = null
        return
      }

      try {
        await QuoteTemplateService.deleteQuoteTemplate(this.templateDeleted)
        this.updateNotification('Template Deleted', true)
      } catch (e) {
        this.updateNotification(e.response.data.message, false)
      }
      await this.getQuoteTemplates()
      this.isConfirmModalActive = false
    },

    editTemplate(template) {
      this.isEditModalActive = true
      this.templateUpdated = template
    },

    filterList() {
      this.persistFilter()
      this.getQuoteTemplates()
    },

    async getQuoteTemplates() {
      this.isTableLoading = true
      this.retrieveFilter()
      this.templates = await QuoteTemplateService.getQuoteTemplates(this.filter)
      this.totalRows = this.templates.length > 0 ? this.templates[0].totalRows : 0
      this.isTableLoading = false
    },

    onHeaderButtonClicked(action) {
      switch(action) {
        case AppHeaderButtonTypes.AddNew:
          this.activeNewTemplate()
          break
      }
    },

    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      this.persistFilter()
      this.getQuoteTemplates()
    },

    persistFilter() {
      sessionStorage.setItem(this.filterKey, JSON.stringify(this.filter))
    },

    resetFilter() {
      this.filter.name = ''
      this.filter.sortColumn = QuoteTemplateListColumns[0].name
      this.filter.sortOrder = QuoteTemplateListColumns[0].defaultOrder
      this.filter.pageIndex = 1
      this.filter.pageSize = 30
      this.persistFilter()
      this.getQuoteTemplates()
    },

    retrieveFilter() {
      const filter = JSON.parse(sessionStorage.getItem(this.filterKey))
      if (filter) {
        this.filter.name = filter.name
        this.filter.sortColumn = filter.sortColumn
        this.filter.sortOrder = filter.sortOrder
        this.filter.pageIndex = filter.pageIndex
        this.filter.pageSize = filter.pageSize
      }
    },

    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
      this.persistFilter()
      this.getQuoteTemplates()
    },

    updateNotification(msg, successStatus) {
      if (successStatus) {
        this.$toast.open({
          message: msg,
          type: 'is-success'
        })
      }
      else {
        this.$toast.open({
          message: msg,
          type: 'is-danger',
          duration: 5000
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.action-column {
  width: 10%;
}
</style>