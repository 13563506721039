<template>
  <base-modal-ex :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    has-modal-card>
    <div class="model-content">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            <slot name="text-title" />
          </p>
        </header>
        <section class="modal-card-body">
          <div class="field">
            <label class="label">Quote Template Name</label>
            <input v-model="templateName"
              class="input is-uppercase"
              type="text"
              placeholder="Template Name">
            <span v-if="$v.templateName.$dirty && !$v.templateName.required"
              class="help is-danger ml-1">Name Required</span>
            <span v-if="$v.templateName.$dirty && !$v.templateName.unique"
              class="help is-danger ml-1">Name has been taken.</span>
          </div>
        </section>
        <footer class="modal-card-foot"
          :style="`justify-content: flex-${buttonLocation}`">
          <button class="button is-primary"
            @click="update">Update</button>
          <button class="button"
            @click="close">Close</button>
        </footer>
      </div>
    </div>
  </base-modal-ex>
</template>
<script>

import BaseModalEx from '@/components/BulmaModal/BaseModalEx'
import QuoteTemplateService from '@/services/QuoteTemplateService'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'QuoteTemplateEditModal',
  components: {
    BaseModalEx
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'fadeIn'
    },
    animationOut: {
      type: String,
      default: 'fadeOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end'
    },
    template: {
      type: Object,
      default: null
    },
    templates: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isActive: this.active || false,
      templateName: null
    }
  },
  computed: {
    templateNames() {
      return this.templates.map(template => template.name)
    }
  },
  validations: {
    templateName: {
      required,
      unique(value) {
        return !this.templateNames.includes(value)
      }
     }
  },
  watch: {
    active(value) {
      this.isActive = value
    }
  },
  methods: {
    close() {
      this.isActive = false
      this.$emit('update:active', false)
    },
    async update() {
      this.$v.$touch()
      if (this.$v.$error) return
      this.template.name = this.templateName.toUpperCase()
      this.$showSpinner()
      await QuoteTemplateService.updateQuoteTemplate(this.template)
      this.close()
      this.$hideSpinner()
    }
  }
}
</script>
